import React, { useState } from "react";
import {
  Bar,
  BarChart,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { useSelector } from "react-redux";
import { formatAmount } from "../../utils/commonFunctions";
import { Button } from "rsuite";

const OverstockInventoryBarGraph = (props: any) => {
  const { barGraphData, barGraphData1, onbarClick, selectedCluster, goBack ,selectedGBPFilter ,selectedRowData} =
    props;

  const windowSize = useSelector(
    (state: any) => state.CommonReducer.windowSize
  );


  console.log(barGraphData, barGraphData1);
  

  return (
    <div className="w-full ">
      <div className="flex">
        <span className="text-white font-medium text-0.95vw ">
          {barGraphData1
            ?  selectedRowData?.length > 0 ? `Inventory Details for Other Active Locations - ${selectedRowData[0]?.cluster} Cluster` : `Constrained Overstock Qty Breakdown Across Plants - ${selectedCluster} Cluster`
            :   selectedRowData?.length > 0 ? `Inventory Details for Other Active Locations - ${selectedRowData[0]?.cluster} Cluster` : selectedGBPFilter?.clustername?.length > 0 ? `Inventory Details -  ${selectedGBPFilter?.clustername[0]} Cluster`:  selectedGBPFilter?.plant?.length > 0 ? `Inventory Details -  ${selectedGBPFilter?.plant[0]} Plant` : "Inventory Details for Other Active Locations (Within the Cluster)"}{" "}
        </span>
        
        {barGraphData1 && (
          <Button
            onClick={() => {
              goBack();
            }}
            size="xs"
            appearance="ghost"
            className={` bg-green-500 text-white
                 border-black border-l-2 hover:bg-green-900 hover:text-white focus:bg-green-500 focus:text-white ml-3`}
          >
            Go Back
          </Button>
        )}
      </div>
      {(barGraphData1 != null && barGraphData1?.length > 0) || barGraphData?.length > 0 ?
      <ResponsiveContainer
        width={"100%"}
        height={windowSize?.width / 6.5}
        className={`border-[1px] border-mainGray`}
      >
        <ComposedChart
          width={500}
          height={300}
          data={barGraphData1 ? barGraphData1 : barGraphData}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 30,
          }}
        >
          <XAxis
            fontSize={10}
            stroke="#B5B5B5"
            dataKey="plant_code"
            angle={-45}
            dx={0}
            dy={15}
            minTickGap={-200}
            axisLine={false}
          />
          <YAxis
            stroke="#B5B5B5"
            fontSize={10}
            tickFormatter={(value: any) => value && `${formatAmount(value)}`}
          />
           <YAxis  yAxisId="right-axis"  hide={true}  domain={[0, 150]}
                orientation="right"  />
          <Tooltip
            cursor={{ fill: "transparent" }}
            content={($) => <CustomTooltip data={$} />}
          />
          <Legend verticalAlign="top" />
          <Bar
            onClick={($:any) =>
              !barGraphData1
                ? onbarClick([{ plant: $?.plant_code, type: 1 }])
                : ""
            }
            name="Remaining Inventory (Qty)"
            dataKey="onhand"
            stackId="a"
            barSize={30}
            fill="#bbed9b"
          />
          <Bar
            onClick={($:any) =>
              !barGraphData1
                ? onbarClick([{ plant: $?.plant_code, type: 1 }])
                : ""
            }
            name="Constrained Overstock (Qty)"
            dataKey="ConstrainedOverstockQty"
            stackId="a"
            barSize={30}
            fill="#f78383"
          />
          <Line
            strokeWidth={3}
            type="monotone"
            name="DOH"
            dataKey="doh"
            stroke="#82ca9d"
             yAxisId="right-axis"
          />
        </ComposedChart>
      </ResponsiveContainer>
 : <><div className="flex h-full justify-center items-center font-bold text-white">No Active Location within the Cluster</div></>
}

    </div>
  );
};

const renderCustomizedLabel = (props: any) => {
  const { x, y, width, value, name } = props;
  console.log(props);
  return (
    <>
      <text
        x={x + width / 2}
        y={y - (value > 0 ? 10 : -10)}
        fill="#fff"
        textAnchor="middle"
        dominantBaseline="middle"
      >
        {parseFloat(value) * 100}%
      </text>
    </>
  );
};

const CustomTooltip = (props: any) => {
  const { active, payload } = props.data;
  if (active && payload && payload.length) {
    return (
      <>
        <div className="bg-[#353535] p-3">
          <>
            <p className="text-white text-0.95vw">
              {payload[0]?.payload?.cluster}
            </p>
            {payload?.map(($: any) => {
              return (
                <>
                  <p className="text-white text-0.7vw">
                    {$?.name} : {formatAmount(Number($?.value))}
                  </p>
                </>
              );
            })}
          </>
        </div>
      </>
    );
  }

  return null;
};

export default OverstockInventoryBarGraph;
